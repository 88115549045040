import React from 'react';

const Live = () => {
  return (
    <section></section>
  )
   
}

export default Live
